import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'

import { Layout } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, type } from '../styles/global'

const DefaultPage = props => {
    const { previewData, pageContext } = props
    const data = previewData || pageContext.acf
    const title = pageContext?.title || pageContext?.data?.title
    console.log(data)

    return (
        <Layout
            meta={data && data.seo}
            headerOptions={{...data?.headerOptions}}
        >
            <Wrapper>
                <Container>
                    <Grid>
                        <Sidebar>
                            {data.sidebarContent && (
                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: data.sidebarContent.text,
                                    }}
                                />
                            )}
                        </Sidebar>
                        <Main>
                            {data.mainContent.text && (
                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: data.mainContent.text,
                                    }}
                                />
                            )}
                            {data.mainContent.links && (
                                <Links
                                    dangerouslySetInnerHTML={{
                                        __html: data.mainContent.links,
                                    }}
                                />
                            )}
                        </Main>
                    </Grid>
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Text = styled.div``
const Links = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.4s ease;
`


// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    min-height: 100vh;
`

const Container = styled.div`
    ${container}
    ${padding}
    min-height: 100vh;
`


const Sidebar = styled.div``
const Main = styled.div``

const Grid = styled.div`
    ${tw`flex`};
    min-height: 100vh;
    padding-top: 1rem;
    padding-bottom: 6rem;

    ${media.mob`
        ${tw`flex-col`};
    `}

    ${Sidebar} {
        ${tw`w-1/3`};
        padding-right: 2.5rem;

        ${media.mob`
            ${tw`w-full pr-0`};
            margin-top: 6rem;
        `}

        ${Text} {
            &, * {
                ${type.body}
            }

            p:not(:first-child) {
                margin-top: 1em;
            }
        }
    }

    ${Main} {
        ${tw`flex flex-col w-2/3`};

        ${media.mob`
            ${tw`w-full`};
            order: -1;
        `}

        ${Text}, ${Links} {
            ${type.heading}
        }

        ${Links} {
            ${tw`mt-auto`};

            ${media.mob`
                margin-top: 3rem;
            `}

            a {
                display: inline;
                ${tw`border-b border-grey`};
                line-height: 1.2;
                border-bottom-width: 2px;
            }
        }
    }
`


export default DefaultPage
