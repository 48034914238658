import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { useMount } from 'react-use'
import { use100vh } from 'react-div-100vh'

import { parseACF } from '../utils'
import { Layout } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage, colours } from '../styles/global'

import { IsMobileContext } from '../context/IsMobileContext'
import { ThemeContext } from '../context/ThemeContext'

const Index = props => {
    const { previewData, pageContext } = props
    const data = previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const { isMobile } = useContext(IsMobileContext)
    const theme = useContext(ThemeContext)
    const screenHeight = use100vh()
    // console.log(data)

    useMount(() => {
        theme.setTheme({
            headerColour: colours.white,
            bgColour: colours.white,
            footerColour: colours.blue
        })
    })

    return (
        <Layout
            meta={data && data.seo}
            footerTheme={colours.blue}
            slides={data.home_slider}
        >
            <Wrapper>
                <Container>
                    <Info
                        dangerouslySetInnerHTML={{
                            __html: data.text,
                        }}
                    />
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.4s ease;
`


// Layout

const Wrapper = styled.div`
    flex-grow: 1;
    ${tw`relative flex flex-col justify-center`};
    background-color: white;

    ${media.mob`
        ${tw`block`};
    `}
`

const Container = styled.div`
    ${container}
    ${padding}

    ${Info} {
        padding-top: 3rem;
        padding-bottom: 6rem;
        width: 68rem;
        max-width: 100%;

        ${media.mob`
            padding-top: 10rem;
            padding-bottom: 13rem;
        `}

        &, * {
            ${tw`font-display text-black`};
            font-weight: 300;
            font-size: 2.25rem;
            line-height: 1.19;
        }

        > *:not(:first-child) {
            margin-top: 1em;
        }
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Index
