import React, { useState, useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import ProgressiveImage from 'react-progressive-image'
import { useMount } from 'react-use'

import { parseACF } from '../utils'
import { Layout } from '../components'
import { media } from '../styles/utils'
import { container, innerContainer, padding, bgImage, colours, type } from '../styles/global'
import { headerHeight, mobHeaderHeight } from '../components/Header/Header'

import { IsMobileContext } from '../context/IsMobileContext'
import { ThemeContext } from '../context/ThemeContext'

const About = props => {
    const { previewData, pageContext } = props
    const data = previewData || parseACF(useStaticQuery(query), 'allWordpressInfopages')
    const { isMobile } = useContext(IsMobileContext)
    const theme = useContext(ThemeContext)
    // console.log(data)

    useMount(() => {
        theme.setTheme({
            headerColour: colours.blue,
            bgColour: colours.peach,
            footerColour: colours.blue
        })
    })

    const renderAboutUs = () => {
        return (
            <AboutUs>
                <Heading>About Us</Heading>
                {data.about_content && (
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: data.about_content,
                        }}
                    />
                )}
            </AboutUs>
        )
    }

    const renderServices = () => {
        let items
        if (data.our_services) {
            items = data.our_services.map((item, i) => {
                return (
                    <Item key={i}>
                        {item.service}
                    </Item>
                )
            })
        }

        return (
            <Services>
                <Subheading>Our Services</Subheading>
                <Items>
                    {items && items}
                </Items>
            </Services>
        )
    }

    const renderClients = () => {
        let items
        if (data.our_clients) {
            items = data.our_clients.map((item, i) => {
                return (
                    <React.Fragment
                        key={i}
                    >
                        <Item
                            as={ExternalLink}
                            href={`${item?.client_link}`}
                            target="_blank"
                        >
                            {`${item?.client_name}`}
                        </Item>
                        <span>&nbsp;/&nbsp;</span>
                    </React.Fragment>
                )
            })
        }
        return (
            <Clients>
                <Subheading>Our Clients</Subheading>
                <Items>
                    {items && items}
                </Items>
            </Clients>
        )
    }

    return (
        <Layout
            meta={data && data.seo}
            footerTheme={colours.blue}
        >
            <Wrapper>
                <Container>
                    <Intro
                        dangerouslySetInnerHTML={{
                            __html: data.about_intro,
                        }}
                    />
                    <Grid>
                        {renderAboutUs()}
                        {renderServices()}
                    </Grid>
                    {renderClients()}
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const ExternalLink = styled.a``
const Items = styled.div``
const Item = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.4s ease;
`


// Layout

const Wrapper = styled.div`
    ${tw`w-full`};
    padding-top: ${headerHeight}px;

    ${media.mob`
        padding-top: ${mobHeaderHeight}px;
    `}
`

const Container = styled.div`
    ${container}
    ${innerContainer}
    ${padding}
`

const Grid = styled.div`
    ${tw`flex justify-between`};

    ${media.mob`
        ${tw`block`};
    `}
`


// Introduction

const Intro = styled.div`
    width: 45rem;
    max-width: 100%;
    padding-top: 9rem;
    padding-bottom: 8rem;

    &, * {
        ${tw`font-display text-blue`};
        font-weight: 300;
        font-size: 2.25rem;
        line-height: 1.19;
    }

    > *:not(:first-child) {
        margin-top: 1em;
    }
`


// About Us

const AboutUs = styled.div`
    ${tw`w-2/3`};

    ${media.mob`
        ${tw`w-full`};
    `}

    ${Heading} {
        ${type.heading}
    }

    ${Description} {
        margin-top: 2.5rem;

        &, * {
            ${type.body}
        }

        p:not(:first-child) {
            margin-top: 1em;
        }
    }
`

// Services

const Services = styled.div`
    padding: 0 3rem;

    ${media.mob`
        margin-top: 8rem;
        ${tw`px-0`};
    `}

    ${Subheading} {
        ${type.heading}
    }

    ${Items} {
        ${tw`flex flex-col`};
        margin-top: 2.5rem;
        
        ${Item} {
            ${type.body}
        }
    }
`

// Clients

const Clients = styled.div`
    margin-top: 7rem;
    margin-bottom: 8rem;

    ${Subheading} {
        ${type.heading}
    }

    ${Items} {
        ${tw`flex flex-wrap`};
        margin-top: 3rem;

        ${media.mob`
            ${tw`flex-col`};
        `}

        ${Item}, span {
            ${tw`font-display`};
            font-weight: 300;
            font-size: 1.6875rem;
            line-height: 1.56;
        }

        ${Item} {
            ${tw`text-black`};
            transition: color 0.4s ease;

            &:hover, &:active {
                ${tw`text-blue`};
            }
        }

        span {
            ${media.mob`
                display: none;
            `}
        }
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "about" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default About
